var win = $(window),
touch = false,
docHeight,
winWidth,
winHeight,
scrollpane,
fadeCoords = [],
fadeHeight = [],
_qg = {};


_qg.ua = (function(){
	
	var _ua = navigator.userAgent.toLowerCase();
	var _o = {
		ltIE6:typeof window.addEventListener == "undefined" && typeof document.documentElement.style.maxHeight == "undefined",
		ltIE7:typeof window.addEventListener == "undefined" && typeof document.querySelectorAll == "undefined",
		ltIE8:typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined",
		IE9: navigator.appVersion.toLowerCase().indexOf("msie 9.") != -1,
		IE10 : navigator.userAgent.match(/Trident\/[6]/i),
		IE11: navigator.userAgent.match(/Trident\/7\./),
		IE:document.uniqueID,
		Firefox:window.sidebar,
		Opera:window.opera,
		chrome: _ua.indexOf('chrome') > -1,
		//Webkit:!document.uniqueID && !window.opera && !window.sidebar && !window.orientation && window.localStorage,
		mobile:/android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()),
		iphone:/iphone|ipod/i.test(_ua),
		android:/android/.test(_ua),
		ipad:/ipad/.test(_ua),
		tablet:undefined,
		smartphone:undefined
	}
	_o.tablet = _o.ipad;
	if(!_o.tablet && _o.android){ _o.tablet = !(/mobile/.test(_ua));}				
	_o.smartphone = _o.iphone || _o.android ? true : false;
	
	var v = [];
	if (/iP(hone|od|ad)/.test(navigator.platform)) {v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);}
	var vAry = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	_o.iosV = vAry[0] ? vAry[0] : '';
	
	
	return _o;
})();



$(document).ready(function(){

	// if($('#header .toggle-nav-button:visible').length){
	// 	var navScrolloptions = {};

	// 	if(_qg.ua.iphone || _qg.ua.ipad) {
	// 		navScrolloptions = {
	// 			disablePointer: true,
	// 			click: true
	// 		}
	// 	}
		
	// 	var navScroll = new IScroll('#nav-scroll', navScrolloptions);
	// }

	// if(_qg.ua.IE){
	// 	$('body').addClass('ua-ie');
	// }

	// $('#header .sub-nav .lang, #header .sub-nav .my-menu').on('click', function(){
	// 	$(this).find('ul').slideToggle(400, 'easeOutCubic');
	// });

	$('#g-nav li').on('mouseenter', function(){
		if(!$('#header .toggle-nav-button:visible').length)
		$(this).find('.child').stop().slideDown(400, 'easeOutCubic');
	});

	$('#g-nav li').on('mouseleave', function(){
		if(!$('#header .toggle-nav-button:visible').length)
		$(this).find('.child').stop().slideUp(100, 'easeOutCubic');
	});

	// $('.toggle-nav-button').on('click', function(e){
	// 	e.preventDefault();
	// 	$(this).toggleClass('opened');
	// 	$('#g-nav').slideToggle(400, function(){
	// 		if(navScroll)
	// 		navScroll.refresh();
	// 	});
	// });	

	// $('#g-nav .toggle-button').on('click', function(){
	// 	$(this).next('.child').slideToggle(400, 'easeOutCubic', function(){
	// 		navScroll.refresh();
	// 	});
	// 	$(this).toggleClass('opened');
	// });	


	$('.anchor, #nav-bar a, .local-nav a').on('click', function(e){
		var tar = $(this).attr('href');
		pageScrollTo(tar, e);
	});	

	
});

$.event.add(window, "load", function(){	

	var slider = new Swiper('.hero-slider', {
			loop: true,
			speed: 2000,
			autoplay: {
				delay: 5000,
			},
			autoplayDisableOnInteraction: false,
			effect: 'fade',
			spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
			autoplay: {
				disableOnInteraction: false
			}	
		});

		//resizeHandler();


});


function pageScrollTo(tar, e){

	var base = location.protocol + '//' + location.hostname;

	if(location.pathname != '/' && !location.pathname)
	base +=  '/' + location.pathname;

	if(!location.pathname || location.pathname == '/'){
		base += '/';
	}

	var tar = tar.replace(base, '');

	//alert(tar)
	var str = tar.slice(0, 1);

	if(str == '#'){

		if($('#nav-bar').is(':visible') && $('.hamburger-button').is(':visible')){
			$('#nav-bar').slideUp();
		}

		if(e)
		e.preventDefault();

			tar = tar.replace('#', '');

		if($('[data-id="' + tar + '"]').length){
			var pos = tar == '#header' ? 0 : $('[data-id="'+tar+'"]').offset().top - 180;

			// if(isHomeFirstLoaded){
			// 	setTimeout(function(){
			// 		$('html,body').stop().animate({ scrollTop: pos }, 1000, 'easeOutExpo');
			// 	}, 2000)				
			// } else {
				$('html,body').stop().animate({ scrollTop: pos }, 1000, 'easeOutExpo');
			//}
		}

	}

}
